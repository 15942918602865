<template>
  <div class="mapbox-popup-container rounded" style="min-width: 240px">
    <div class="popup-content" style="padding: 0">
      <div
        class="
          bg-default-dark
          text-uppercase text-wrap text-center
          font-weight-bold
          h6
          mb-0
          p-3
        "
      >
        {{ data.name }}
      </div>
      <div class="p-2">
        <table class="table popup-info mb-0">
          <tbody>
            <tr>
              <td>Type</td>
              <td class="text-capitalize">{{ data.type }}</td>
            </tr>
            <tr>
              <td>Date Added</td>
              <td class="text-capitalize">
                {{ Helper.formatDate(data.date) }}
              </td>
            </tr>
            <tr>
              <td>Severity</td>
              <td class="text-capitalize">{{ data.severity }}</td>
            </tr>
            <tr>
              <td>Location</td>
              <td>
                {{
                  Helper.formatCoordinate([
                    data.coordinates[1],
                    data.coordinates[0],
                  ])
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="px-3 pb-3">
        <div class="btn-group w-100" role="group">
          <button
            v-for="tab in Helper.siteTabs().filter(
              (t) => !data.hiddenTabs.includes(t.name)
            )"
            :key="`tab-${tab.name}`"
            class="btn bg-default-dark border-text-default-dark"
            @click="goTo(tab.name)"
            data-toggle="tooltip"
            :title="`View ${tab.label}`"
            @mouseenter="Helper.showTooltip($event)"
            @mouseleave="Helper.showTooltip($event, 'hide')"
          >
            <i class="fas icon" :class="`fa-${tab.icon} text-default-dark`" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  mounted() {
    if (Math.ceil(this.$el.clientWidth) % 2) {
      this.$el.style.width = Math.ceil(this.$el.clientWidth) + 1 + "px";
    }
  },
};
</script>