<template>
  <div class="position-relative map-view">
    <div id="dashboard-map" class="w-100 h-100"></div>
    <div class="map-floating-button" v-if="showMapButton">
      <button
        class="btn shadow-sm"
        :class="isSatelliteView ? 'btn-success' : 'btn-dark text-default-dark'"
        data-toggle="tooltip"
        title="Toggle satellite view"
        @mouseenter="Helper.showTooltip($event)"
        @click="isSatelliteView = !isSatelliteView"
      >
        <i class="fas fa-satellite"></i>
      </button>
    </div>
    <div id="map-footer" v-if="currentRegion">
      <div class="precipitation-legend">
        <p class="mb-2 text-default-dark">Rain Intensity</p>
        <ul class="list-unstyled mb-0 small">
          <li>
            <i class="fas fa-circle mr-2 text-info" />
            Light Rain (0.1mm - 2.5mm)
          </li>
          <li>
            <i class="fas fa-circle mr-2 text-primary" />
            Moderate Rain (2.6mm - 7.6mm)
          </li>
          <li>
            <i class="fas fa-circle mr-2 text-warning" />
            Heavy Rain (7.7mm - 50mm)
          </li>
          <li>
            <i class="fas fa-circle mr-2 text-danger" />
            Violent Rain (> 50mm)
          </li>
        </ul>
      </div>
    </div>
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import Spinner from "@/components/Spinner.vue";
import SiteInfoPopup from "@/components/SiteInfoPopup";
import Vue from "vue";

export default {
  name: "view-map",
  props: ["hideSidebar"],
  components: {
    Spinner,
    SiteInfoPopup,
  },
  data() {
    return {
      isLoading: false,
      showMapButton: false,
      isSatelliteView: false,
      map: null,
      sites: [],
      regions: [],
      currentRegion: null,
      userRegions: [],
      userSites: [],
    };
  },
  watch: {
    hideSidebar: {
      handler() {
        if (this.map) {
          setTimeout(() => {
            this.map.resize();
          }, 300);
        }
      },
      immediate: true,
      deep: true,
    },
    isSatelliteView: {
      handler() {
        if (this.map) {
          let styleUrl = this.Helper.mapStyle(this.isSatelliteView);

          this.map.setStyle(styleUrl);
        }
      },
      immediate: false,
    },
  },
  methods: {
    clearMap() {
      if (this.map) {
        this.map.remove();

        this.map = null;
      }
    },

    initMap() {
      return new Promise((resolve) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        this.map = new mapboxgl.Map({
          container: "dashboard-map",
          style: this.Helper.mapStyle(),
          center: [101.5179483, 3.075603444131706],
          zoom: 8,
        });

        this.map.on("load", async () => {
          await this.loadMapImages();

          await this.setMarkers(
            this.sites.filter((site) => site.lat && site.lng)
          );

          this.setMapBounds(this.sites.filter((site) => site.lat && site.lng));

          this.showMapButton = true;

          this.isLoading = false;

          resolve();
        });
      });
    },
    setMapBounds(sites) {
      if (sites.length > 0 && sites.length <= 1) {
        this.map.setCenter([sites[0].lng, sites[0].lat]);
      } else if (sites.length > 1) {
        let bounds = new mapboxgl.LngLatBounds();

        sites.forEach(function (marker) {
          bounds.extend([marker.lng, marker.lat]);
        });

        this.map.fitBounds(bounds, {
          padding: 150,
          duration: 0,
        });
      }
    },
    loadMapImages() {
      return new Promise((resolve) => {
        this.map.loadImage(
          require("@/assets/icons/flood.png"),
          async (errFlood, imageFlood) => {
            if (errFlood) {
              console.error(errFlood);

              return;
            }

            this.map.addImage("marker-flood", imageFlood);

            this.map.loadImage(
              require("@/assets/icons/landslide.png"),
              async (errLandslide, imageLandslide) => {
                if (errLandslide) {
                  console.error(errLandslide);

                  return;
                }

                this.map.addImage("marker-landslide", imageLandslide);

                this.map.loadImage(
                  require("@/assets/icons/cluster.png"),
                  async (errCluster, imageCluster) => {
                    if (errCluster) {
                      console.error(errCluster);

                      return;
                    }

                    this.map.addImage("cluster", imageCluster);

                    this.map.loadImage(
                      require("@/assets/icons/alert.png"),
                      async (errAlert, imageAlert) => {
                        if (errAlert) {
                          console.error(errAlert);

                          return;
                        }

                        this.map.addImage("marker-alert", imageAlert);

                        resolve();
                      }
                    );
                  }
                );
              }
            );
          }
        );
      });
    },
    clearMarkers() {
      if (this.map) {
        const layers = this.map
          .getStyle()
          .layers.filter((layer) => layer.id.includes("marker-layer"));

        const sources = Object.keys(this.map.getStyle().sources).filter(
          (source) => source.includes("marker-source")
        );

        if (layers && layers.length > 0) {
          layers.forEach((layer) => {
            this.map.removeLayer(layer.id);
          });
        }

        if (sources && sources.length > 0) {
          sources.forEach((source) => {
            this.map.removeSource(source);
          });
        }
      }
    },
    async setMarkers(sites) {
      return new Promise((resolve) => {
        this.clearMarkers();

        setTimeout(() => {
          if (sites && sites.length > 0) {
            this.map.addSource("marker-source", {
              type: "geojson",
              cluster: true,
              data: {
                type: "FeatureCollection",
                features: sites.map((site) => {
                  return {
                    type: "Feature",
                    properties: {
                      id: site._id,
                      type: site.type,
                      name: site.name,
                      date: site.createdAt,
                      severity: site.severity,
                      hiddenTabs: site.hidden_tabs || [],
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [site.lng, site.lat],
                    },
                  };
                }),
              },
            });

            this.map.addLayer({
              id: "marker-layer-cluster",
              type: "symbol",
              source: "marker-source",
              filter: ["has", "point_count"],
              layout: {
                "icon-image": "cluster",
                "icon-size": 1,
                "text-field": "{point_count_abbreviated}",
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-size": 14,
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
              },
              paint: {
                "text-color": "#000000",
              },
            });

            this.map.addLayer({
              id: "marker-layer-point",
              type: "symbol",
              source: "marker-source",
              filter: ["!", ["has", "point_count"]],
              layout: {
                "icon-image": [
                  "match",
                  ["get", "type"],
                  "flood",
                  "marker-flood",
                  "landslide",
                  "marker-landslide",
                  "marker-alert",
                ],
                "icon-size": 0.06,
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "text-field": ["get", "name"],
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-size": 13,
                "text-offset": [0, 2],
                "text-justify": "center",
              },
              paint: {
                "text-color": "#FAF8ED",
                "text-halo-width": 1,
                "text-halo-color": "#88857a",
                "text-halo-blur": 3,
              },
            });

            this.map.on(
              "click",
              "marker-layer-cluster",
              this.mapClusterClickEvent
            );

            this.map.on(
              "click",
              "marker-layer-point",
              this.mapMarkerClickEvent
            );

            this.map.on(
              "mouseenter",
              "marker-layer-cluster",
              this.mapMouseCursorPointer
            );

            this.map.on(
              "mouseleave",
              "marker-layer-cluster",
              this.mapMouseCursorDefault
            );

            this.map.on(
              "mouseenter",
              "marker-layer-point",
              this.mapMouseCursorPointer
            );

            this.map.on(
              "mouseleave",
              "marker-layer-point",
              this.mapMouseCursorDefault
            );
          }

          resolve();
        }, 500);
      });
    },
    mapMarkerClickEvent(e) {
      const SiteInfoPopupClass = Vue.extend(SiteInfoPopup);

      const site = {
        coordinates: e.features[0].geometry.coordinates.slice(),
        id: e.features[0].properties.id,
        date: e.features[0].properties.date,
        name: e.features[0].properties.name,
        type: e.features[0].properties.type,
        severity: e.features[0].properties.severity,
        hiddenTabs: JSON.parse(e.features[0].properties.hiddenTabs),
      };

      const popup = new mapboxgl.Popup()
        .setLngLat([e.lngLat.lng, e.lngLat.lat])
        .setHTML(`<div id="site-info-popup-content-${site.id}"></div>`)
        .addTo(this.map);

      const vm = this;

      const popupInstance = new SiteInfoPopupClass({
        propsData: {
          data: site,
        },
        methods: {
          goTo(tab) {
            vm.$router.push({
              name: "disaster.site",
              params: {
                id: site.id,
              },
              query: {
                tab: tab,
              },
            });
          },
        },
      });

      popupInstance.$mount(`#site-info-popup-content-${site.id}`);

      popup._update();
    },
    mapClusterClickEvent(e) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["marker-layer-cluster"],
      });

      const clusterId = features[0].properties.cluster_id;

      this.map
        .getSource("marker-source")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    },
    mapMouseCursorPointer() {
      this.map.getCanvas().style.cursor = "pointer";
    },
    mapMouseCursorDefault() {
      this.map.getCanvas().style.cursor = "";
    },
    async getSites() {
      let url = "sites?_sort=name";

      if (this.userRegions.length > 0) {
        url += `&region_in=${this.userRegions.join("&region_in=")}`;
      }

      if (this.userSites.length > 0) {
        url += `&id_in=${this.userSites.join("&id_in=")}`;
      }

      const [siteCall, siteCallErr] = await this.Helper.handle(
        this.API.get(url)
      );

      if (!siteCallErr && siteCall.status == 200) {
        this.sites = siteCall.data;
      }

      this.initMap();
    },
    async getRegions() {
      if (this.userRegions && this.userRegions.length > 0) {
        const [regionCall, regionCallErr] = await this.Helper.handle(
          this.API.get(`regions?_id_in=${this.userRegions.join("&_id_in=")}`)
        );

        if (!regionCallErr && regionCall.status == 200) {
          this.regions = regionCall.data;

          this.getSites();
        } else {
          this.isLoading = false;
        }
      } else {
        this.initMap();
      }
    },
    async getUserRegions() {
      this.isLoading = true;

      const [userCall, userCallErr] = await this.Helper.handle(
        this.API.get("users/me")
      );

      if (!userCallErr && userCall.status == 200) {
        this.userRegions = userCall.data.regions ? userCall.data.regions : [];

        this.userSites = userCall.data.sites ? userCall.data.sites : [];

        this.getRegions();
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getUserRegions();
  },
  beforeDestroy() {
    this.clearMap();
  },
};
</script>